'use client';

import 'mantine-datatable/styles.layer.css';

import { createTheme, MantineColorsTuple, MantineProvider as MantineProviderInner } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useLocale } from 'next-intl';
import { PropsWithChildren } from 'react';
export const PRIMARY_COLOR_TUPLE: MantineColorsTuple = [
    '#e7f0ff',
    '#cedbff',
    '#9bb4ff',
    '#638aff',
    '#3666ff',
    '#1850ff',
    '#0245ff',
    '#0037e5',
    '#0030cd',
    '#0028b5'
];

export const PRIMARY_COLOR = PRIMARY_COLOR_TUPLE[4];
export const PRIMARY_FONT_FAMILY = 'var(--inter-font)';

export const theme = createTheme({
    primaryColor: 'primary',
    fontFamily: PRIMARY_FONT_FAMILY,
    colors: {
        primary: PRIMARY_COLOR_TUPLE
    }
});

export default function MantineProvider (props: PropsWithChildren) {
    const locale = useLocale();

    return (
        <MantineProviderInner
            theme={theme}
            forceColorScheme={'light'}
        >
            <DatesProvider settings={{ locale }}>
                {props.children}
            </DatesProvider>
        </MantineProviderInner>
    );
}